import AzulInfiniteCardImage from '../assets/images/cards/azul-infinite-card.png'
import AzulPlatinumCardImage from '../assets/images/cards/azul-platinum-card.png'
import LatamPassBlackCardImage from '../assets/images/cards/latam-pass-black-card.png'
import LatamPlatinumCardImage from '../assets/images/cards/latam-platinum-card.png'
import MastercardBlackCardImage from '../assets/images/cards/mastercard-black-card.png'
import PaoDeAcucarCardImage from '../assets/images/cards/pao-de-acucar-card.png'
import PassaiGoldCardImage from '../assets/images/cards/passai-gold-card.png'
import PdaBlackCardImage from '../assets/images/cards/pda-black-card.png'
import UniclassCardImage from '../assets/images/cards/uniclass-card.png'

const cards = [
  {
    title: 'Renegocie dívidas de diversas empresas em um só lugar',
    subtitle:
      'Conheça as condições e aumente as chances de aprovação de crédito',
    taxText: 'de desconto à vista',
    buttonText: 'Continuar',
    description:
      'Identificamos uma dívida em seu nome e a Recovery é especialista em te ajudar a renegociar ela (seja com bancos, lojas, entre outros), para você conseguir as melhores condições e voltar a ter crédito no mercado',
    productCode: 'recovery_debts',
    buttonAriaLabel: 'renegocie agora',
    buttonTrackingName: 'Renegocie',
    heroOfferHighlights: [
      {
        icon: 'consorcio_outline',
        text: 'Descontos especiais à vista'
      },
      {
        icon: 'calendario_outline',
        text: 'Parcelamento em até 72 vezes'
      },
      {
        icon: 'favorito_outline',
        text: 'Online e sem burocracias'
      }
    ],
    secondOfferHighlights: [
      {
        icon: 'consorcio_outline',
        text: 'Descontos especiais à vista'
      },
      {
        icon: 'calendario_outline',
        text: 'Parcelamento em até 72 vezes'
      }
    ]
  },
  {
    title: 'Crédito com garantia de imóvel',
    buttonText: 'Continuar',
    productCode: 'cgi',
    buttonAriaLabel: 'continuar',
    buttonTrackingName: 'EmprestimoCGI',
    heroOfferHighlights: [
      {
        icon: 'calendario_outline',
        text: 'Até 15 anos para pagar'
      },
      {
        icon: 'credito_outline',
        text: 'Pagamento via débito automático'
      },
      {
        icon: 'consorcio_outline',
        text: 'Receba em uma conta Itaú'
      }
    ],
    secondOfferHighlights: [
      {
        icon: 'calendario_outline',
        text: 'Até 15 anos para pagar'
      },
      {
        icon: 'credito_outline',
        text: 'Pagamento via débito automático'
      }
    ]
  },
  {
    title: 'Antecipação do Saque-Aniversário FGTS',
    buttonText: 'Continuar',
    productCode: 'FGTS',
    buttonAriaLabel: 'continuar',
    buttonTrackingName: 'FGTS',
    heroOfferHighlights: [
      {
        icon: 'conta_corrente_outline',
        text: 'Receba em qualquer banco. Não precisa ter uma conta Itaú'
      },
      {
        icon: 'credito_outline',
        text: 'Sem prestações mensais. O desconto é direto no FGTS'
      },
      {
        icon: 'consorcio_outline',
        text: 'Receba em até 24h'
      }
    ],
    secondOfferHighlights: [
      {
        icon: 'conta_corrente_outline',
        text: 'Receba em qualquer banco. Não precisa ter uma conta Itaú'
      },
      {
        icon: 'credito_outline',
        text: 'Sem prestações mensais. O desconto é direto no FGTS'
      }
    ]
  },
  {
    title: 'Antecipação do Saque-Aniversário FGTS',
    buttonText: 'Continuar',
    productCode: 'fgts_app',
    buttonAriaLabel: 'Continuar',
    buttonTrackingName: 'fgts_app',
    heroOfferHighlights: [
      {
        icon: 'consorcio_outline',
        text: 'Sem prestações mensais. O desconto é direto no FGTS'
      },
      {
        icon: 'credito_outline',
        text: 'Receba em até 24h'
      },
      {
        icon: 'more_benefits_base',
        text: 'Disponível até para negativados'
      }
    ],
    secondOfferHighlights: [
      {
        icon: 'consorcio_outline',
        text: 'Sem prestações mensais. O desconto é direto no FGTS'
      },
      {
        icon: 'credito_outline',
        text: 'Receba em até 24h'
      }
    ]
  },
  {
    title: 'Empréstimo consignado Itaú',
    buttonText: 'Continuar',
    loanLabel: 'Valor do empréstimo',
    productCode: 'consig_ncor',
    buttonAriaLabel: 'Continuar',
    buttonTrackingName: 'ConsigNcor',
    heroOfferHighlights: [
      {
        icon: 'calendario_outline',
        text: 'Receba o dinheiro no mesmo dia'
      },
      {
        icon: 'consorcio_outline',
        text: 'Receba o dinheiro em qualquer banco'
      }
    ],
    secondOfferHighlights: [
      {
        icon: 'calendario_outline',
        text: 'Receba o dinheiro no mesmo dia'
      }
    ]
  },
  {
    title: 'Empréstimo Itaú',
    buttonText: 'Continuar',
    productCode: 'default',
    buttonAriaLabel: '',
    buttonTrackingName: '',
    heroOfferHighlights: [
      {
        icon: 'consorcio_outline',
        text: 'Receba o dinheiro'
      }
    ],
    secondOfferHighlights: [
      {
        icon: 'consorcio_outline',
        text: 'Receba o dinheiro'
      }
    ]
  },
  {
    title: 'Renegocie dívidas com parcelas que cabem no seu bolso',
    taxText: 'descontos especiais à vista',
    buttonText: 'Continuar',
    subtitle: 'Condições especiais para que você recupere seu crédito ',
    description: '',
    productCode: 'reneg_debts',
    buttonAriaLabel: 'renegociar dívidas',
    buttonTrackingName: 'Renegocie',
    heroOfferHighlights: [
      {
        icon: 'consorcio_outline',
        text: 'Descontos de até 99%'
      },
      {
        icon: 'calendario_outline',
        text: 'Parcelamento em até 72 vezes'
      },
      {
        icon: 'favorito_outline',
        text: 'Simples, rápido, fácil e online'
      }
    ],
    secondOfferHighlights: [
      {
        icon: 'consorcio_outline',
        text: 'Descontos de até 99%'
      },
      {
        icon: 'calendario_outline',
        text: 'Parcelamento em até 72 vezes'
      }
    ]
  },
  {
    title: 'Empréstimo consignado na sua conta Itaú',
    buttonText: 'Continuar',
    productCode: 'CONSIG CORR OP',
    buttonAriaLabel: 'continuar',
    buttonTrackingName: 'ConsignadoCORROP',
    heroOfferHighlights: [
      {
        icon: 'calendario_outline',
        text: 'Até 3 meses para começar a pagar'
      },
      {
        icon: 'salario_outline',
        text: 'Pagamento descontado do seu sálario'
      },
      {
        icon: 'consorcio_outline',
        text: 'Receba o dinheiro na hora na sua conta Itaú'
      }
    ],
    secondOfferHighlights: [
      {
        icon: 'salario_outline',
        text: 'Pagamento descontado do seu sálario'
      }
    ]
  },
  {
    title: 'Empréstimo consignado na sua conta Itaú',
    buttonText: 'Continuar',
    productCode: 'CONSIG CORR EP',
    buttonAriaLabel: 'continuar',
    buttonTrackingName: 'ConsignadoCORREP',
    heroOfferHighlights: [
      {
        icon: 'calendario_outline',
        text: 'Até 3 meses para começar a pagar'
      },
      {
        icon: 'salario_outline',
        text: 'Pagamento descontado do seu sálario'
      },
      {
        icon: 'consorcio_outline',
        text: 'Receba o dinheiro na hora na sua conta Itaú'
      }
    ],
    secondOfferHighlights: [
      {
        icon: 'salario_outline',
        text: 'Pagamento descontado do seu sálario'
      }
    ]
  },
  {
    title: 'Empréstimo consignado na sua conta Itaú',
    buttonText: 'Continuar',
    productCode: 'CONSIG CORR OP / Person',
    buttonAriaLabel: 'continuar',
    buttonTrackingName: 'ConsignadoCORROPPerson',
    heroOfferHighlights: [
      {
        icon: 'calendario_outline',
        text: 'Até 3 meses para começar a pagar'
      },
      {
        icon: 'salario_outline',
        text: 'Pagamento descontado do seu sálario'
      },
      {
        icon: 'consorcio_outline',
        text: 'Receba o dinheiro na hora na sua conta Itaú'
      }
    ],
    secondOfferHighlights: [
      {
        icon: 'salario_outline',
        text: 'Pagamento descontado do seu salário'
      }
    ]
  },
  {
    title: 'Empréstimo consignado na sua conta Itaú',
    buttonText: 'Continuar',
    productCode: 'CONSIG CORR EP / Person',
    buttonAriaLabel: 'continuar',
    buttonTrackingName: 'ConsignadoCORREPPerson',
    heroOfferHighlights: [
      {
        icon: 'calendario_outline',
        text: 'Até 3 meses para começar a pagar'
      },
      {
        icon: 'salario_outline',
        text: 'Pagamento descontado do seu salário'
      },
      {
        icon: 'consorcio_outline',
        text: 'Receba o dinheiro na hora na sua conta Itaú'
      }
    ],
    secondOfferHighlights: [
      {
        icon: 'salario_outline',
        text: 'Pagamento descontado do seu salário'
      }
    ]
  },
  {
    title: 'Refinancie seu crédito consignado Itaú',
    buttonText: 'Continuar',
    productCode: 'CONSIG CORR REFIN EP',
    buttonAriaLabel: 'continuar',
    buttonTrackingName: 'ConsignadoCORRRefinEP',
    heroOfferHighlights: [
      {
        icon: 'calendario_outline',
        text: 'Até 3 meses para começar a pagar'
      }
    ],
    secondOfferHighlights: []
  },
  {
    title: 'Refinancie seu crédito consignado Itaú',
    buttonText: 'Continuar',
    productCode: 'CONSIG CORR REFIN OP',
    buttonAriaLabel: 'continuar',
    buttonTrackingName: 'ConsignadoCORRRefinOP',
    heroOfferHighlights: [
      {
        icon: 'calendario_outline',
        text: 'Até 3 meses para começar a pagar'
      }
    ],
    secondOfferHighlights: []
  },
  {
    title: 'Refinancie seu crédito consignado Itaú',
    buttonText: 'Continuar',
    productCode: 'CONSIG CORR REFIN EP / Person',
    buttonAriaLabel: 'continuar',
    buttonTrackingName: 'ConsignadoCORRRefinEPPerson',
    heroOfferHighlights: [
      {
        icon: 'calendario_outline',
        text: 'Até 3 meses para começar a pagar'
      }
    ],
    secondOfferHighlights: []
  },
  {
    title: 'Refinancie seu crédito consignado Itaú',
    buttonText: 'Continuar',
    productCode: 'CONSIG CORR REFIN OP / Person',
    buttonAriaLabel: 'continuar',
    buttonTrackingName: 'ConsignadoCORRRefinOPPerson',
    heroOfferHighlights: [
      {
        icon: 'calendario_outline',
        text: 'Até 3 meses para começar a pagar'
      }
    ],
    secondOfferHighlights: []
  },
  {
    title: 'Refinancie seu crédito consignado Itaú',
    buttonText: 'Continuar',
    productCode: 'CONSIG CORR REFIN INSS',
    buttonAriaLabel: 'continuar',
    buttonTrackingName: 'ConsignadoCORRRefinINSS',
    heroOfferHighlights: [
      {
        icon: 'calendario_outline',
        text: 'Até 3 meses para começar a pagar'
      },
      {
        icon: 'salario_outline',
        text: 'Pagamento descontado do seu benefício INSS'
      },
      {
        icon: 'consorcio_outline',
        text: 'Receba o dinheiro na hora na sua conta Itaú'
      }
    ],
    secondOfferHighlights: [
      {
        icon: 'salario_outline',
        text: 'Pagamento descontado do seu benefício INSS'
      }
    ]
  },
  {
    title: 'Refinancie seu crédito consignado Itaú',
    buttonText: 'Continuar',
    productCode: 'CONSIG CORR REFIN INSS / Person',
    buttonAriaLabel: 'continuar',
    buttonTrackingName: 'ConsignadoCORRRefinINSSPerson',
    heroOfferHighlights: [
      {
        icon: 'calendario_outline',
        text: 'Até 3 meses para começar a pagar'
      },
      {
        icon: 'salario_outline',
        text: 'Pagamento descontado do seu benefício INSS'
      },
      {
        icon: 'consorcio_outline',
        text: 'Receba o dinheiro na hora na sua conta Itaú'
      }
    ],
    secondOfferHighlights: [
      {
        icon: 'salario_outline',
        text: 'Pagamento descontado do seu benefício INSS'
      }
    ]
  },
  {
    title: 'Refinanciamento empréstimo consignado',
    taxText:
      'Refinancie seu \u003cspan\u003ecrédito consignado\u003c/span\u003e no Itaú e \u003cspan\u003eaproveite as vantagens\u003c/span\u003e',
    buttonText: 'Continuar',
    description: '*Proposta sujeita à análise de crédito',
    productCode: 'consig_refin_inss_ncor',
    buttonAriaLabel: 'continuar',
    buttonTrackingName: 'RenovacaoConsignado',
    heroOfferHighlights: [
      {
        icon: 'calendario_outline',
        text: 'Mais tempo para pagar'
      },
      {
        icon: 'salario_outline',
        text: 'Possibilidade de reducão de taxas de juros'
      },
      {
        icon: 'consorcio_outline',
        text: 'Possibilidade de liberar valor adicional na sua conta'
      }
    ],
    secondOfferHighlights: [
      {
        icon: 'calendario_outline',
        text: 'Mais tempo para pagar'
      },
      {
        icon: 'salario_outline',
        text: 'Possibilidade de reducão de taxas de juros'
      },
      {
        icon: 'consorcio_outline',
        text: 'Possibilidade de liberar valor adicional na sua conta'
      }
    ]
  },
  {
    title: 'Refinanciamento empréstimo consignado Itaú',
    buttonText: 'Continuar',
    productCode: 'CONSIG REFIN INSS',
    buttonAriaLabel: 'continuar',
    buttonTrackingName: 'RefinINSS',
    heroOfferHighlights: [],
    secondOfferHighlights: []
  },
  {
    title: 'Aproveite as vantagens da conta Itaú',
    buttonText: 'Continuar',
    highlights: [
      {
        icon: 'limite_outline',
        text: 'Limite da Conta para usar em caso de imprevistos'
      },
      {
        icon: 'calendario_outline',
        text: 'Livre de mensalidade'
      },
      {
        icon: 'consorcio_outline',
        text: 'Investimentos à partir de R$ 1,00'
      }
    ],
    productCode: 'itau_account',
    subtitleBig:
      'Movimentar a conta pode aumentar suas chances de conseguir um empréstimo',
    buttonAriaLabel: 'abrir conta',
    buttonTrackingName: 'itauAccount'
  },
  {
    title: 'Peça já o seu cartão Azul Infinite',
    buttonText: 'Continuar',
    highlights: [
      {
        icon: 'presente_outline',
        text: 'Ganhe até 60 mil pontos bônus'
      },
      {
        icon: 'programa_de_pontos_outline',
        text: 'Pontuação de U$$ 1 = 3,0 pontos'
      },
      {
        icon: 'viagem_outliner',
        text: 'Acompanhante grátis em 2 viagens e 3 bagagens grátis em voos nacionais e internacionais'
      }
    ],
    productCode: 'azul_infinite_card',
    subtitleBig: '',
    subtitleSmall: '',
    buttonAriaLabel: 'Pedir cartão',
    buttonTrackingName: 'azulInfiniteCard',
    image: AzulInfiniteCardImage
  },
  {
    title: 'Peça já o seu cartão Azul Platinum',
    buttonText: 'Continuar',
    highlights: [
      {
        icon: 'consorcio_outline',
        text: 'Limite máximo de R$ 35.000,00'
      },
      {
        icon: 'programa_de_pontos_outline',
        text: 'Pontuação de U$$ 1 = 2,2 pontos'
      },
      {
        icon: 'calendario_outline',
        text: 'Anuidade grátis gastando R$ 4.000,00 por mês'
      }
    ],
    productCode: 'azul_platinum_card',
    subtitleBig: '',
    subtitleSmall: '',
    buttonAriaLabel: 'Pedir cartão',
    buttonTrackingName: 'azulPlatinumCard',
    image: AzulPlatinumCardImage
  },
  {
    title: 'Peça já o seu cartão Pão de Açúcar Internacional',
    buttonText: 'Continuar',
    highlights: [
      {
        icon: 'consorcio_outline',
        text: 'Limite de até R$ 10.000,00'
      },
      {
        icon: 'calendario_outline',
        text: 'Anuidade grátis sem gasto mínimo'
      },
      {
        icon: 'presente_outline',
        text: '20% OFF: Vinhos, Queijos, Cervejas Especiais'
      }
    ],
    productCode: 'pda_inter_card',
    subtitleBig: '',
    subtitleSmall: '',
    buttonAriaLabel: 'pedir cartão',
    buttonTrackingName: 'PaoDeAcucarCard',
    image: PaoDeAcucarCardImage
  },
  {
    title: 'Peça já o seu cartão Uniclass Signature',
    buttonText: 'Continuar',
    highlights: [
      {
        icon: 'calendario_outline',
        text: 'Anuidade grátis'
      },
      {
        icon: 'consorcio_outline',
        text: 'Limite de até R$ 50.000,00 no cartão'
      }
    ],
    productCode: 'uniclass_signature_card',
    subtitleBig: '',
    subtitleSmall: '',
    buttonAriaLabel: 'pedir cartão',
    buttonTrackingName: 'UniclassSignature',
    image: UniclassCardImage
  },
  {
    title: 'Peça já o seu cartão Personnalité Black Pontos',
    buttonText: 'Continuar',
    highlights: [
      {
        icon: 'consorcio_outline',
        text: 'Cartão de crédito com pontos que não expiram'
      },
      {
        icon: 'programa_de_pontos_outline',
        text: 'Pontuação de até US$ 1 = 3 pontos'
      },
      {
        icon: 'calendario_outline',
        text: 'Anuidade grátis gastando R$ 10mil por mês'
      }
    ],
    productCode: 'personnalite_black_pontos_card',
    subtitleBig: '',
    subtitleSmall: '',
    buttonAriaLabel: 'pedir cartão',
    buttonTrackingName: 'PersonnaliteBlack',
    image: MastercardBlackCardImage
  },
  {
    title: 'Peça já o seu cartão LATAM Pass Platinum',
    buttonText: 'Continuar',
    highlights: [
      {
        icon: 'consorcio_outline',
        text: 'Limite a partir de R$ 5.500,00'
      },
      {
        icon: 'programa_de_pontos_outline',
        text: 'Pontuação de US$ 1 = 2 pontos'
      },
      {
        icon: 'calendario_outline',
        text: 'Anuidade grátis gastando R$ 4.000,00 por mês'
      }
    ],
    productCode: 'latam_pass_platinum',
    subtitleBig: '',
    subtitleSmall: '',
    buttonAriaLabel: 'pedir cartão',
    buttonTrackingName: 'LatamPassPlatinum',
    image: LatamPlatinumCardImage
  },
  {
    title: 'Peça já o seu cartão LATAM Pass Black',
    buttonText: 'Continuar',
    highlights: [
      {
        icon: 'consorcio_outline',
        text: 'Limite flexível'
      },
      {
        icon: 'programa_de_pontos_outline',
        text: 'Pontuação de até US$ 1 = 3,5 pontos'
      },
      {
        icon: 'calendario_outline',
        text: 'Anuidade grátis gastando R$ 20.000,00 por mês'
      }
    ],
    productCode: 'latam_pass_black',
    subtitleBig: '',
    subtitleSmall: '',
    buttonAriaLabel: 'pedir cartão',
    buttonTrackingName: 'LatamPassBlack',
    image: LatamPassBlackCardImage
  },
  {
    title: 'Peça já o seu cartão Pão de Açúcar Black',
    buttonText: 'Continuar',
    highlights: [
      {
        icon: 'juros',
        text: '20% OFF: Vinhos, Espumantes e em toda linha Qualitá'
      },
      {
        icon: 'programa_de_pontos_outline',
        text: 'Pontuação de até US$ 1 = 5 pontos'
      }
    ],
    productCode: 'pda_black_card',
    subtitleBig: '',
    subtitleSmall: '',
    buttonAriaLabel: 'pedir cartão',
    buttonTrackingName: 'PdaBlack',
    image: PdaBlackCardImage
  },
  {
    title: 'Peça já o seu cartão Passaí Gold',
    buttonText: 'Continuar',
    highlights: [
      {
        icon: 'cifrao',
        text: 'Preço de atacado a partir de 1 unidade com o cartão'
      },
      {
        icon: 'juros',
        text: 'Condições exclusivas de parcelamento no cartão de crédito'
      },
      {
        icon: 'consorcio_outline',
        text: 'Renda mínima R$ 800,00'
      }
    ],
    productCode: 'passai_gold_card',
    subtitleBig: '',
    subtitleSmall: '',
    buttonAriaLabel: 'pedir cartão',
    buttonTrackingName: 'PassaiGold',
    image: PassaiGoldCardImage
  },
  {
    title: 'Empréstimo Consignado Itaú',
    buttonText: 'Continuar',
    highlights: [
      {
        icon: 'consorcio_outline',
        text: 'Receba o dinheiro no mesmo dia'
      },
      {
        icon: 'calendario_outline',
        text: 'Receba o dinheiro em qualquer banco'
      },
      {
        icon: 'consorcio_outline',
        text: 'Simples, rápido, fácil e online'
      }
    ],
    productCode: 'consig_pcon_inss_cold',
    subtitleBig: 'Condições especiais para que você recupere seu crédito',
    buttonAriaLabel: 'continuar',
    buttonTrackingName: 'consig_pcon_inss_cold'
  },
  {
    title: 'Empréstimo Consignado Itaú',
    buttonText: 'Continuar',
    highlights: [
      {
        icon: 'consorcio_outline',
        text: 'Receba o dinheiro no mesmo dia'
      },
      {
        icon: 'calendario_outline',
        text: 'Receba o dinheiro em qualquer banco'
      },
      {
        icon: 'consorcio_outline',
        text: 'Simples, rápido, fácil e online'
      }
    ],
    productCode: 'consig_pcon_op_cold',
    subtitleBig: 'Condições especiais para que você recupere seu crédito',
    buttonAriaLabel: 'continuar',
    buttonTrackingName: 'consig_pcon_op_cold'
  },
  {
    title: 'Empréstimo Consignado Itaú',
    buttonText: 'Continuar',
    highlights: [
      {
        icon: 'consorcio_outline',
        text: 'Receba o dinheiro no mesmo dia'
      },
      {
        icon: 'calendario_outline',
        text: 'Receba o dinheiro em qualquer banco'
      },
      {
        icon: 'consorcio_outline',
        text: 'Simples, rápido, fácil e online'
      }
    ],
    productCode: 'consig_pa_ep_cold',
    subtitleBig: 'Condições especiais para que você recupere seu crédito',
    buttonAriaLabel: 'continuar',
    buttonTrackingName: 'consig_pa_ep_cold'
  },
  {
    title: 'Temos um consórcio que faz o seu tipo',
    buttonText: 'ir para o app',
    heroOfferHighlights: [
      {
        icon: 'juros',
        text: 'sem entrada e sem juros'
      },
      {
        icon: 'cifrao',
        text: 'você escolhe o valor que pretende pagar'
      },
      {
        icon: 'calendario_outline',
        text: 'todo mês alguem é contemplado'
      },
      {
        icon: 'more_benefits_base',
        text: 'planejamento e segurança em um só lugar'
      }
    ],
    secondOfferHighlights: [
      {
        icon: 'juros',
        text: 'sem entrada e sem juros'
      },
      {
        icon: 'cifrao',
        text: 'você escolhe o valor que pretende pagar'
      },
      {
        icon: 'calendario_outline',
        text: 'todo mês alguem é contemplado'
      },
      {
        icon: 'more_benefits_base',
        text: 'planejamento e segurança em um só lugar'
      }
    ],
    productCode: 'consortium',
    buttonAriaLabel: 'ir para o app',
    buttonTrackingName: 'consorcio'
  }
]

export default cards
