import {
  BUILD_ENV,
  DATADOG_APP_ID,
  DATADOG_CLIENT_TOKEN
} from 'gatsby-env-variables'

import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

const init = () => {
  datadogRum.init({
    clientToken: DATADOG_CLIENT_TOKEN,
    applicationId: DATADOG_APP_ID,
    site: 'datadoghq.com',
    service: 'itau-jn6-app-360-shopping-www',
    env: `${BUILD_ENV}`,
    sessionSampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    actionNameAttribute: 'datadog-action'
  })
}

const logsInit = () => {
  datadogLogs.init({
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'itau-jn6-app-360-shopping-www',
    env: `${BUILD_ENV}`,
    forwardConsoleLogs: ['warn', 'error']
  })
}

export default {
  init,
  logsInit
}
