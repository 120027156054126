const buildEnv = process.env.BUILD_ENV

const prodShoppingRoutes = {
  home: {
    path: '/',
    component: 'src/pages/Home/index.js',
    title:
      'Empréstimo Pessoal e Consignado Online | Simule seu empréstimo agora com o Itaú',
    category: 'home'
  },
  resultadoFgtsWebAppSuccess: {
    path: '/resultado/final-screen-fgts-web-app',
    component: 'src/pages/Result/FgtsWebAppSuccess/index.js',
    title: 'Empréstimo Pessoal e Consignado Online | Final',
    category: 'proposal'
  },
  resultadoConsorcio: {
    path: '/resultado/final-screen-consortium-summary-success',
    component: 'src/pages/Result/Consorcio/index.js',
    title: 'Empréstimo Pessoal e Consignado Online | Final',
    category: 'proposal'
  },
  resultadoFgtsSuccess: {
    path: '/resultado/final-screen-fgts-success',
    component: 'src/pages/Result/FgtsSuccess/index.js',
    context: {
      header: {
        title: 'Shopping de Empréstimos'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Final',
    category: 'proposal'
  },
  resultadoConsignadoSuccess: {
    path: '/resultado/consignado-summary-success',
    component: 'src/pages/Result/ConsignadoSuccess/index.js',
    context: {
      header: {
        title: 'Shopping de Empréstimos'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Final',
    category: 'proposal'
  },
  resultadoAzulInfiniteCard: {
    path: '/resultado/final-screen-azul-infinite-card-summary-success',
    component: 'src/pages/Result/AzulInfiniteCard/index.js',
    context: {
      header: {
        title: 'Shopping de Empréstimos'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Final',
    category: 'proposal'
  },
  resultadoRecovery: {
    path: '/resultado/final-screen-recovery',
    component: 'src/pages/Result/Recovery/index.js',
    context: {
      header: {
        title: 'Shopping de Empréstimos'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Final',
    category: 'proposal'
  },
  resultadoReneg: {
    path: '/resultado/final-screen-reneg',
    component: 'src/pages/Result/Reneg/index.js',
    context: {
      header: {
        title: 'Shopping de Empréstimos'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Final',
    category: 'proposal'
  },
  resultadoItauAccount: {
    path: '/resultado/itau-account-summary-success',
    component: 'src/pages/Result/ItauAccount/index.js',
    context: {
      header: {
        title: 'Shopping de Empréstimos'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Final',
    category: 'proposal'
  },
  resultadoPaoDeAcucarCard: {
    path: '/resultado/final-screen-pao-de-acucar-card',
    component: 'src/pages/Result/PaoDeAcucarCard/index.js',
    context: {
      header: {
        title: 'Shopping de Empréstimos'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Final',
    category: 'proposal'
  },
  resultadoAzulPlatinumCard: {
    path: '/resultado/final-screen-azul-platinum-card-summary-success',
    component: 'src/pages/Result/AzulPlatinumCard/index.js',
    context: {
      header: {
        title: 'Shopping de Empréstimos'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Final',
    category: 'proposal'
  },
  resultadoCGI: {
    path: '/resultado/cgi-summary-success',
    component: 'src/pages/Result/CGI/index.js',
    context: {
      header: {
        title: 'Shopping de Empréstimos'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Final',
    category: 'proposal'
  },
  offers: {
    path: '/proposta/ofertas',
    component: 'src/pages/Offers/index.js',
    context: {
      header: {
        title: 'Shopping de Empréstimos'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Ofertas',
    category: 'proposal'
  },
  notFound: {
    path: '/nao-encontrado',
    component: 'src/pages/404/index.js',
    context: {
      header: {
        title: 'Shopping de Empréstimos'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Página não encontrada',
    category: 'proposal'
  },
  skipper: {
    path: '/skipper',
    component: 'src/pages/Skipper/index.js',
    context: {
      header: {
        title: 'Shopping de Empréstimos'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online',
    category: 'proposal'
  },
  FormalizationFinished: {
    path: '/proposta/sms-formalizacao',
    component: 'src/pages/Formalization/Finished/index.js',
    category: 'proposal',
    context: {
      header: {
        title: 'Shopping de Empréstimos'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Formalização '
  },
  smsCodeVerification: {
    path: '/proposta/verificacao-codigo-sms',
    component: 'src/pages/Proposal/SmsCodeVerification/index.js',
    context: {
      header: {
        title: 'Shopping de Empréstimos'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Proposta Verificação SMS',
    category: 'proposal'
  },
  newSmsCodeVerification: {
    path: '/proposta/verificacao-token-sms',
    component: 'src/pages/Proposal/NewSmsCodeVerification/index.js',
    context: {
      header: {
        title: 'Shopping de Empréstimos'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Proposta Verificação SMS',
    category: 'proposal'
  },
  desiredLoanData: {
    path: '/proposta/valor-desejado',
    component: 'src/pages/Proposal/DesiredLoanData/index.js',
    context: {
      header: {
        title: 'Shopping de Empréstimos'
      }
    },
    title:
      'Empréstimo Pessoal e Consignado Online | Proposta Valor do Empréstimo',
    category: 'proposal'
  },
  selectOccupation: {
    path: '/proposta/profissao',
    component: 'src/pages/Proposal/SelectOccupation/index.js',
    context: {
      header: {
        title: 'Shopping de Empréstimos'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Proposta Fonte de Renda',
    category: 'proposal'
  },
  selectGoal: {
    path: '/proposta/motivo',
    component: 'src/pages/Proposal/SelectGoal/index.js',
    context: {
      header: {
        title: 'Shopping de Empréstimos'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Proposta Motivo',
    category: 'proposal'
  },
  selectMicroGoal: {
    path: '/proposta/necessidade',
    component: 'src/pages/Proposal/SelectMicroGoal/index.js',
    context: {
      header: {
        title: 'Shopping de Empréstimos'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Proposta Motivo',
    category: 'proposal'
  },
  selectGoalCustomize: {
    path: '/proposta/motivo/customizar',
    component: 'src/pages/Proposal/SelectGoal/Customize/index.js',
    context: {
      header: {
        title: 'Shopping de Empréstimos'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Proposta Motivo',
    category: 'proposal'
  },
  purposeDiagnosis: {
    path: '/proposta/diagnostico',
    component: 'src/pages/Proposal/Diagnosis/index.js',
    context: {
      header: {
        title: 'Shopping de Empréstimos'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Proposta Diagnóstico',
    category: 'proposal'
  },
  selectMacroGoal: {
    path: '/proposta/grupo-necessidade',
    component: 'src/pages/Proposal/SelectMacroGoal/index.js',
    context: {
      header: {
        title: 'Shopping de Empréstimos'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Proposta Motivo',
    category: 'proposal'
  },
  selectMacroGoalCustomize: {
    path: '/proposta/grupo-necessidade/outros',
    component: 'src/pages/Proposal/SelectMacroGoal/Customize/index.js',
    context: {
      header: {
        title: 'Shopping de Empréstimos'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Proposta Motivo',
    category: 'proposal'
  },
  purposeData: {
    path: '/proposta/motivo-emprestimo',
    component: 'src/pages/Proposal/PurposeData/index.js',
    context: {
      header: {
        isFormalization: true
      }
    },
    title:
      'Empréstimo Pessoal e Consignado Online | Proposta Motivo do Empréstimo',
    category: 'proposal'
  },
  propertyData: {
    path: '/proposta/dados-imovel',
    component: 'src/pages/Proposal/PropertyData/Choose/index.js',
    context: {
      header: {
        title: 'Crédito com garantia de imóvel'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Proposta Imóvel',
    category: 'proposal'
  },
  propertyDataCustomize: {
    path: '/proposta/dados-imovel/customizar',
    component: 'src/pages/Proposal/PropertyData/Customize/index.js',
    context: {
      header: {
        title: 'Crédito com garantia de imóvel'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Proposta Imóvel',
    category: 'proposal'
  },
  propertyDataUnavailable: {
    path: '/proposta/dados-imovel/indisponivel',
    component: 'src/pages/Proposal/PropertyData/Unavailable/index.js',
    context: {
      header: {
        title: 'Crédito com garantia de imóvel'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Proposta Imóvel',
    category: 'proposal'
  },
  sms: {
    path: '/sms',
    component: 'src/pages/Sms/index.js',
    context: {
      header: {
        title: 'Shopping de Empréstimos'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Token Sms',
    category: 'proposal'
  },
  searchingCredit: {
    path: '/proposta/pesquisando-credito',
    component: 'src/pages/SearchingCredit/index.js',
    context: {
      header: {
        title: 'Shopping de Empréstimos'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Procurando Ofertas',
    category: 'proposal'
  },
  Agreement: {
    path: '/proposta/fonte-de-renda',
    component: 'src/pages/Proposal/Agreement/index.js',
    context: {
      header: {
        title: 'Shopping de Empréstimos'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Proposta Fonte de Renda',
    category: 'proposal'
  },
  FormalizationPersonalData: {
    path: '/proposta/dados-pessoais-formalizacao',
    component: 'src/pages/Formalization/PersonalData/index.js',
    context: {
      header: {
        isFormalization: true
      }
    },
    title:
      'Empréstimo Pessoal e Consignado Online | Formalização Dados Pessoais ',
    category: 'proposal'
  },
  FormalizationDocument: {
    path: '/proposta/formalizacao-documento',
    component: 'src/pages/Formalization/Documentation/index.js',
    context: {
      header: {
        isFormalization: true
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Formalização Documento ',
    category: 'proposal'
  },
  FormalizationAddress: {
    path: '/proposta/endereco-residencial',
    component: 'src/pages/Formalization/Address/index.js',
    context: {
      header: {
        isFormalization: true
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Formalização Endereço ',
    category: 'proposal'
  },
  FormalizationBanking: {
    path: '/proposta/dados-bancarios',
    component: 'src/pages/Formalization/BankData/index.js',
    context: {
      header: {
        isFormalization: true
      }
    },
    title:
      'Empréstimo Pessoal e Consignado Online | Formalização Dados Bancários ',
    category: 'proposal'
  },
  FormalizationFinishedNCor: {
    path: '/proposta/sms-formalizacao-ncor',
    component: 'src/pages/Formalization/Finished/index.js',
    context: {
      header: {
        isFormalization: true
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Formalização ',
    category: 'proposal'
  },
  AllowFgtsApp: {
    path: '/proposta/permitir-app-fgts',
    component: 'src/pages/AllowFGTSApp/index.js',
    title: 'Empréstimo Pessoal e Consignado Online | Permitir App Fgts ',
    category: 'proposal'
  },
  proposalName: {
    path: '/proposta/nome',
    component: 'src/pages/Proposal/BasicData/Name/index.js',
    context: {
      header: {
        title: 'Shopping de Empréstimos'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Proposta',
    category: 'proposal'
  },
  proposalEmail: {
    path: '/proposta/email',
    component: 'src/pages/Proposal/BasicData/Email/index.js',
    context: {
      header: {
        title: 'Shopping de Empréstimos'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Proposta',
    category: 'proposal'
  },
  proposalPhone: {
    path: '/proposta/telefone',
    component: 'src/pages/Proposal/BasicData/Phone/index.js',
    context: {
      header: {
        title: 'Shopping de Empréstimos'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online | Proposta',
    category: 'proposal'
  },
  ErrorAttemptLimit: {
    path: '/erro/limite-de-tentativas',
    component: 'src/pages/Error/index.js',
    title: 'Empréstimo Pessoal e Consignado Online',
    context: {
      type: 'error_attempt_limit'
    }
  },
  ErrorSomethingWrong: {
    path: '/erro/algo-esta-errado',
    component: 'src/pages/Error/index.js',
    title: 'Empréstimo Pessoal e Consignado Online',
    context: {
      type: 'error_something_wrong'
    }
  }
}

const notProdShoppingRoutes = {
  allCards: {
    path: '/todos-cards',
    component: 'src/pages/AllCards/index.js',
    context: {
      header: {
        title: 'Shopping de Empréstimos'
      }
    },
    title: 'Empréstimo Pessoal e Consignado Online',
    category: 'proposal'
  }
}

const shoppingRoutes =
  buildEnv !== 'production'
    ? {
        ...notProdShoppingRoutes,
        ...prodShoppingRoutes
      }
    : { ...prodShoppingRoutes }

module.exports = shoppingRoutes
