import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { isClient, storage } from 'utils'

const getDefaultInfo = () => {
  if (!isClient()) return {}

  const tokensAtstore = storage.session.getItem('token') || {}

  const anonymousId = window?.analytics?._user?.anonymousId() || 'unknown'
  const leadID = tokensAtstore?.leadId || 'unknown'
  const currentHref = window.location.href || 'unknown'
  const previousPage = window.previousPath || 'unknown'

  const pathName = window.location.pathname
  const currentPath =
    pathName === '/' ? 'home' : pathName?.replaceAll('/', '') || 'unknown'

  return {
    anonymousId,
    leadID,
    currentHref,
    currentPath,
    previousPage
  }
}

export const DDPageView = ({ pageName = '', payload = {} }) => {
  const defaultInfo = getDefaultInfo()
  const data = { ...defaultInfo, ...payload }

  datadogRum.addAction(pageName, data)
}

export const DDPageError = ({ pageName = '', name = '', payload = {} }) => {
  const defaultInfo = getDefaultInfo()

  const currentPageName = pageName || defaultInfo['currentPath']

  const data = { name, ...defaultInfo, ...payload, flag: 'error-dashboard' }

  datadogLogs.logger.error(`Page:${currentPageName}`, {}, data)
}
