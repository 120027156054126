const rootPath = '/'
const errorPath = '/erro'
const proposalPath = '/proposta'
const resultadoPath = '/resultado'
const notFoundPath = '/nao-encontrado'
const searchingCreditPath = '/proposta/pesquisando-credito'
const cepFGTSPath = '/proposta/localizacao'

const allRoutePaths = [
  ...Object.entries(ROUTES).reduce((acc, [, { path }]) => [...acc, path], []),
  cepFGTSPath
]

const isApiPath = (path) => !/\//.test(path)

const dictionary = {
  error_attempt_limit: `${errorPath}/limite-de-tentativas`,
  error_something_wrong: `${errorPath}/algo-esta-errado`,
  home: rootPath,
  'basic-data': rootPath,
  'full-name': `${proposalPath}/nome`,
  email: `${proposalPath}/email`,
  'phone-number': `${proposalPath}/telefone`,
  'send-tokenization-code': `${proposalPath}/verificacao-codigo-sms`,
  'validate-tokenization-code-otp': `${proposalPath}/verificacao-token-sms`,
  'validate-tokenization-code': `${proposalPath}/verificacao-codigo-sms`,
  'desired-loan-data': `${proposalPath}/valor-desejado`,
  'select-occupation': `${proposalPath}/profissao`,
  'select-goal': `${proposalPath}/motivo`,
  'select-micro-goal': `${proposalPath}/necessidade`,
  'select-goal-customize': `${proposalPath}/motivo/customizar`,
  'select-target-user-goal': `${proposalPath}/diagnostico`,
  'select-macro-goal': `${proposalPath}/grupo-necessidade`,
  'select-macro-goal-others': `${proposalPath}/grupo-necessidade/outros`,
  'agreement-data': `${proposalPath}/fonte-de-renda`,
  'purpose-data': `${proposalPath}/motivo-emprestimo`,
  'property-data': `${proposalPath}/dados-imovel`,
  'property-data-customize': `${proposalPath}/dados-imovel/customizar`,
  'property-data-unavailable': `${proposalPath}/dados-imovel/indisponivel`,
  'simulation-summary': searchingCreditPath,
  'proposal-summary': searchingCreditPath,
  'zipcode-data': cepFGTSPath,
  'personal-data': `${proposalPath}/dados-pessoais-formalizacao`,
  'document-data': `${proposalPath}/formalizacao-documento`,
  'residential-address': `${proposalPath}/endereco-residencial`,
  'commercial-address': `${proposalPath}/endereco-residencial`,
  'banking-data': `${proposalPath}/dados-bancarios`,
  'recurrent-loan': `${proposalPath}/emprestimo-recorrente`,
  'desired-loan-type': `${proposalPath}/emprestimo-recorrente`,
  'loan-reason': `${proposalPath}/objetivo`,
  'final-screen-consignado-summary-success': `${resultadoPath}/consignado-summary-success`,
  'final-screen-cgi-summary-success': `${resultadoPath}/cgi-summary-success`,
  'final-screen-itau-account-summary-success': `${resultadoPath}/itau-account-summary-success`,
  'final-screen-azul-infinite-card-summary-success': `${resultadoPath}/final-screen-azul-infinite-card-summary-success`,
  'final-screen-recovery': `${resultadoPath}/final-screen-recovery`,
  'final-screen-fgts-success': `${resultadoPath}/final-screen-fgts-success`,
  'final-screen-reneg': `${resultadoPath}/final-screen-reneg`,
  'final-screen-azul-platinum-card-summary-success': `${resultadoPath}/final-screen-azul-platinum-card-summary-success`,
  'final-screen-pao-de-acucar-card-summary-success': `${resultadoPath}/final-screen-pao-de-acucar-card`,
  'final-screen-consig-ncor-summary-success': `${proposalPath}/sms-formalizacao`,
  'final-screen-online-formalization-summary-success': `${proposalPath}/sms-formalizacao`,
  'final-screen-fgts-web-app': `${resultadoPath}/final-screen-fgts-web-app`,
  'final-screen-allow-fgts': `${proposalPath}/permitir-app-fgts`,
  'final-screen-consortium-summary-success': `${resultadoPath}/final-screen-consortium-summary-success`
}

export default (path) => {
  const url = isApiPath(path) ? dictionary[path] : path

  const mockDomain = 'https://emprestimo.itau.com.br'
  const urlObject = new URL(url, mockDomain)
  const pathname = urlObject.pathname
  const isValidPath = allRoutePaths.indexOf(pathname) !== -1

  if (isValidPath) {
    return url
  } else {
    return notFoundPath
  }
}
