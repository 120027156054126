import { http } from '@itau-loans-www/shopping/src/services'
import { goTo, formatters, storage, isClient } from 'utils'

import services from './services'

export const saveOnContinueTracking = (proposal) => {
  const { number } = formatters

  const {
    buttonTrackingName,
    maxLoanValue = 0,
    requestedLoanValue = 0,
    interestRateMonthly = 0,
    simulationOptionUuid = ''
  } = proposal

  storage.session.setItem(
    'simulationOptionUuid',
    JSON.stringify(simulationOptionUuid)
  )
  storage.session.setItem('ga_details', buttonTrackingName)
  storage.session.setItem('ga_limof', maxLoanValue)
  storage.session.setItem('ga_volsim', requestedLoanValue)
  storage.session.setItem(
    'ga_jurosfinanciamento',
    number.toPercent(interestRateMonthly, 2)
  )
}

export const defaultCallback = ({ proposal, setButtonLoading, setProduct }) => {
  saveOnContinueTracking(proposal)

  const { logo, buttonTrackingName, productCode, simulationOptionUuid } =
    proposal

  const requestBody = {
    simulation_option_uuid: simulationOptionUuid,
    product_code: productCode
  }

  setButtonLoading(true)

  services
    .putSimulationSummary(requestBody)
    .then(({ final_screen, link, next_step }) => {
      setProduct({ proposal, logo, buttonTrackingName, link })

      if (link && productCode === 'FGTS') {
        isClient() && window.location.assign(link)
        return
      }

      if (!final_screen) {
        goTo(next_step)
        return
      }

      goTo(final_screen)
    })
    .catch(() => setButtonLoading(false))
}

export const finishProposalCallback = ({
  proposal,
  setButtonLoading,
  setProduct
}) => {
  saveOnContinueTracking(proposal)

  const { logo, buttonTrackingName, productCode, simulationOptionUuid } =
    proposal

  const requestBody = {
    simulation_option_uuid: simulationOptionUuid,
    product_code: productCode
  }

  const requestPostBody = {
    simulation_option_uuid: simulationOptionUuid,
    contact_type: 'whatsapp'
  }

  setButtonLoading(true)
  services
    .putSimulationSummary(requestBody)
    .then(({ link, final_screen }) => {
      setProduct({ proposal, logo, buttonTrackingName, link })

      http.proposal
        .finishSimulation(productCode, requestPostBody)
        .then((response) => {
          if (response?.link) {
            isClient() && window.open(response?.link, '_blank')
          }

          goTo(final_screen)
        })
        .catch(() => setButtonLoading(false))
    })
    .catch(() => setButtonLoading(false))
}

export const webAppCallback = ({ proposal, setButtonLoading, setProduct }) => {
  setButtonLoading(true)

  saveOnContinueTracking(proposal)

  services
    .finishWebAPPProposal(proposal.simulationOptionUuid)
    .then(({ final_screen, link, qr_code }) => {
      const hasUsedData = !!final_screen || !!link || !!qr_code

      setProduct({ proposal: { ...proposal, link: link, qrcode: qr_code } })

      if (!hasUsedData) {
        throw new Error(
          'hasnt final_screen, link, qr_code | finishWebAPPProposal'
        )
      }

      if (link && !qr_code) {
        isClient() && BUILD_ENV !== 'e2e' && window.open(link, '_blank')
      }

      if (final_screen) {
        goTo(final_screen)
      }
    })
    .catch(() => setButtonLoading(false))
}

export const fgtsCallback = ({ proposal, setButtonLoading, setProduct }) => {
  setButtonLoading(true)
  saveOnContinueTracking(proposal)

  defaultCallback({ proposal, setButtonLoading, setProduct })
}
